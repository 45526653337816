import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 5; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/11-sep-2/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function TeachersDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Teacher's Day Celebrations</h1>

        <p>
          <strong>
            {" "}
            A good teacher can awaken joy in a student and leave a positive
            impression that lasts a lifetime.
          </strong>
          <br />
          <br />
          On the auspicious occasion of Teachers' Day, the senior students of
          Chinmaya Vidyalaya, New Delhi, exhibited their deep reverence and
          admiration for their teachers by bestowing them with special titles
          that served as a heartfelt tribute to the educators. The Principal of
          the school, Ms. Archana Soni, expressed her profound gratitude to the
          teachers. She eloquently highlighted their pivotal role in nurturing
          and shaping the future of the students. The event was marked by an
          array of captivating moments with an outstanding dance performance.
          Additionally, the students lent their voices to a melodious medley of
          songs, which served as a poignant expression of their gratitude
          towards their dedicated teachers. The grand finale was a mesmerizing
          display of dance performances that celebrated the endearing bond
          between students and teachers .
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
